/* MarqueeComponent.css */
.marquee-container {
  width: 100%;
  overflow: hidden;
  padding: 10px;
  border: 0px solid #ddd;
}

.marquee-text {
  margin: 0;
  font-size: 12px;
  color: #333;
}
